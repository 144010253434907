import { type DirectoryNode, type FileSystemTree } from '@webcontainer/api';

export const MainAppFile = `
import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom';
import Generation from './Generation';

REPLACE_IMPORTS 

function App(): ReactElement {
  return (
     <Routes>
        <Route path="/" element={<Generation REPLACE_PROPS />} />
     </Routes>
  )
}

export default App;`;

export const packageJson = `
{
  "name": "vite-temp-app",
  "version": "0.0.0",
  "scripts": {
    "dev": "vite",
    "build": "tsc && vite build",
    "serve": "vite preview --port 3000"
  },
  "dependencies": {
    "vite-tsconfig-paths": "^4.3.2",
    "react": "^18.3.1",
    "react-dom": "18.3.1",
    "react-router-dom": "^6.14.1",
    "@fontsource/inter": "^5.0.5",
    "tailwindcss-animate": "^1.0.7",
    "tailwind-merge": "^2.2.1",
    "lucide-react": "^0.456.0",
    "@phosphor-icons/react": "^2.0.15"
  },
  "devDependencies": {
    "@vitejs/plugin-react": "^3.1.0",
    "autoprefixer": "^10.4.13",
    "postcss": "^8.4.21",
    "tailwindcss": "^3.2.6",
    "typescript": "^4.9.5",
    "vite": "^4.1.1"
  }
}
`.trim();

export const globalsCssBase = `
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    background: var(--background);
    color: var(--foreground);
  }
  :root {
    --shadow-low: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
    --shadow-medium: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    --shadow-high: 0px 1px 8px 0px rgba(17, 17, 17, 0.1), 0px 16px 40px 0px rgba(17, 17, 17, 0.15);
    --background: #ffffff;
    --disabled: #eff1f4;
    --overlay: #262626;
    --container: #f8f9fc;
    --container-high: #eff1f4;
    --inverse: #fbfcff;
    --on-inverse: #f8f9fc;
    --foreground: #0b0707;
    --secondary: #384355;
    --subtle: #60718f;
    --on-disabled: #b1bacb;
    --stroke: #d2d7e1;
    --stroke-strong: #b1bacb;
    --stroke-subtle: #dee2e9;
    --stroke-disabled: #eff1f4;
    --stroke-inverse: #7b8ba7;
    --primary-accent: #f9f9ff;
    --primary-container: #ecf0ff;
    --stroke-primary: #6590de;
    --primary: #2d5da7;
    --on-primary: #ffffff;
    --on-primary-container: #002f66;
    --primary-strong: #80abfb;
    --primary-hover: #07458e;
    --on-primary-hover: #ffffff;
    --primary-pressed: #003a7b;
    --on-primary-pressed: #ffffff;
    --neutral-accent: #f8f9fc;
    --neutral-container: #eff1f4;
    --neutral-stroke: #9ba8bd;
    --neutral: #4c5971;
    --on-neutral: #ffffff;
    --on-neutral-container: #1e242e;
    --neutral-hover: #140d0d;
    --on-neutral-hover: #eff1f4;
    --neutral-pressed: #000000;
    --on-neutral-pressed: #ffffff;
    --success-accent: #e8fff0;
    --success-container: #bdffdd;
    --stroke-success: #00a575;
    --success: #006c4c;
    --on-success: #ffffff;
    --on-success-container: #003826;
    --success-status: #25c28d;
    --success-hover: #005138;
    --on-success-hover: #ffffff;
    --success-pressed: #00452f;
    --on-success-pressed: #ffffff;
    --warning-accent: #fff9ea;
    --warning-container: #fff2ab;
    --stroke-warning: #a39100;
    --warning: #6a5f00;
    --on-warning: #ffffff;
    --on-warning-container: #373100;
    --warning-status: #c0ac00;
    --warning-hover: #504700;
    --on-warning-hover: #ffffff;
    --warning-pressed: #443c00;
    --on-warning-pressed: #ffffff;
    --danger-accent: #fff8f7;
    --danger-container: #ffedeb;
    --stroke-danger: #ff5451;
    --danger: #b91a24;
    --on-danger: #ffffff;
    --on-danger-container: #68000a;
    --danger-strong: #ff5451;
    --danger-hover: #930013;
    --on-danger-hover: #ffffff;
    --danger-pressed: #7d000f;
    --on-danger-pressed: #ffffff;
    --info-accent: #f9f9ff;
    --info-container: #ecf0ff;
    --stroke-info: #6590de;
    --info: #2d5da7;
    --on-info: #ffffff;
    --on-info-container: #002f66;
    --info-strong: #6590de;
    --info-hover: #07458e;
    --on-info-hover: #ffffff;
    --info-pressed: #003a7b;
    --on-info-pressed: #ffffff;
    --radius-xs: 6px;
    --radius-sm: 8px;
    --radius: 10px;
    --radius-md: 12px;
    --radius-lg: 16px;
  }
  .dark {
    --background: #1b1b1c;
    --disabled: #3b3b3c;

    --container: #303031;
    --container-high: #535252;

    --inverse: #e3e2e5;
    --on-inverse: #303031;

    --foreground: #faf8fc;
    --sub: #c7c6c9;
    --soft: #919092;
    --on-disabled: #777778;
    --inverse: #111111;

    --outline: #777778;
    --stroke: #535252;

    --stroke-disabled: #303031;
    --outline-inverse: #5e5e5f;

    --primary-accent: #2a0054;
    --primary-container: #f8edff;
    --primary-sub: #7400d5;
    --primary: #9b45ff;
    --on-primary: #ffffff;
    --on-primary-container: #460085;
    --primary-strong: #8400f1;

    --primary-hover: #b070ff;
    --on-primary-hover: #ffffff;

    --primary-pressed: #9b45ff;
    --on-primary-pressed: #ffffff;

    --neutral-accent: #303031;
    --neutral-container: #535252;
    --neutral-sub: #c7c6c9;
    --neutral: #faf8fc;
    --on-neutral: #111111;
    --on-neutral-container: #f1f0f3;

    --neutral-hover: #e3e2e5;
    --on-neutral-hover: #101010;

    --on-neutral-pressed: #000000;
    --neutral-pressed: #c7c6c9;

    --success-accent: #002114;
    --success-container: #005138;
    --success-sub: #005f42;
    --success: #006c4c;
    --on-success: #ffffff;
    --on-success-container: #6ffbc2;

    --success-hover: #005138;
    --on-success-hover: #ffffff;

    --success-pressed: #00452f;
    --on-success-pressed: #ffffff;

    --warning-accent: #1e1c00;
    --warning-container: #4c4800;
    --warning: #656100;
    --warning-strong: #585400;
    --on-warning: #ffffff;
    --on-warning-container: #f2e71a;
    --stroke-warning: #585400;

    --warning-hover: #4c4800;
    --on-warning-hover: #ffffff;

    --warning-pressed: #403d00;
    --on-warning-pressed: #ffffff;

    --danger-accent: #410004;
    --danger-container: #930013;
    --danger-sub: #a80619;
    --danger: #b91a24;
    --on-danger: #ffffff;
    --on-danger-container: #ffdad7;

    --danger-hover: #930013;
    --on-danger-hover: #ffffff;

    --danger-pressed: #7d000f;
    --on-danger-pressed: #ffffff;

    --info-accent: #001b3f;
    --info-container: #6400ba;
    --info-sub: #c596ff;
    --info: #8400f1;
    --on-info: #460085;
    --on-info-container: #eedbff;

    --info-hover: #07458e;
    --on-info-hover: #ffffff;

    --info-pressed: #003a7b;
    --on-info-pressed: #ffffff;
  }
}
@layer components {
  .primary-focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px var(--primary-container);
  }
  .neutral-focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px var(--neutral-container);
  }
  .danger-focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px var(--danger-container);
  }
}

input:focus-visible {
  box-shadow: none !important;
}

`;

export const tailwindConfigBase = `
module.exports = {
  darkMode: ['class'],
  content: [
  './dist/**/*.html', './src/**/*.{js,jsx,ts,tsx}', './*.html', './node_modules/@tremor/**/*.{js,ts,jsx,tsx}'
  ],
  theme: {
    screens: {
      xs: '320px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
    extend: {
      colors: {
        background: 'var(--background)',
        disabled: 'var(--disabled)',
        overlay: 'var(--overlay)',
        container: {
          DEFAULT: 'var(--container)',
          high: 'var(--container-high)',
        },
        inverse: {
          DEFAULT: 'var(--inverse)',
        },
        on: {
          inverse: 'var(--on-inverse)',
          disabled: 'var(--on-disabled)',
          primary: 'var(--on-primary)',
          'primary-container': 'var(--on-primary-container)',
          'primary-hover': 'var(--on-primary-hover)',
          'primary-pressed': 'var(--on-primary-pressed)',
          neutral: 'var(--on-neutral)',
          'neutral-container': 'var(--on-neutral-container)',
          'neutral-hover': 'var(--on-neutral-hover)',
          'neutral-pressed': 'var(--on-neutral-pressed)',
          success: 'var(--on-success)',
          'success-container': 'var(--on-success-container)',
          'success-hover': 'var(--on-success-hover)',
          'success-pressed': 'var(--on-success-pressed)',
          warning: 'var(--on-warning)',
          'warning-container': 'var(--on-warning-container)',
          'warning-hover': 'var(--on-warning-hover)',
          'warning-pressed': 'var(--on-warning-pressed)',
          danger: 'var(--on-danger)',
          'danger-container': 'var(--on-danger-container)',
          'danger-hover': 'var(--on-danger-hover)',
          'danger-pressed': 'var(--on-danger-pressed)',
          info: 'var(--on-info)',
          'info-container': 'var(--on-info-container)',
          'info-hover': 'var(--on-info-hover)',
          'info-pressed': 'var(--on-info-pressed)',
        },
        foreground: 'var(--foreground)',
        secondary: 'var(--secondary)',
        subtle: 'var(--subtle)',
        stroke: {
          DEFAULT: 'var(--stroke)',
          strong: 'var(--stroke-strong)',
          subtle: 'var(--stroke-subtle)',
          disabled: 'var(--stroke-disabled)',
          inverse: 'var(--stroke-inverse)',
          primary: 'var(--stroke-primary)',
          success: 'var(--stroke-success)',
          warning: 'var(--stroke-warning)',
          danger: 'var(--stroke-danger)',
          info: 'var(--stroke-info)',
        },
        primary: {
          accent: 'var(--primary-accent)',
          container: 'var(--primary-container)',
          DEFAULT: 'var(--primary)',
          strong: 'var(--primary-strong)',
          hover: 'var(--primary-hover)',
          pressed: 'var(--primary-pressed)',
        },
        neutral: {
          accent: 'var(--neutral-accent)',
          container: 'var(--neutral-container)',
          stroke: 'var(--neutral-stroke)',
          DEFAULT: 'var(--neutral)',
          hover: 'var(--neutral-hover)',
          pressed: 'var(--neutral-pressed)',
        },
        success: {
          accent: 'var(--success-accent)',
          container: 'var(--success-container)',
          DEFAULT: 'var(--success)',
          strong: 'var(--success-strong)',
          hover: 'var(--success-hover)',
          pressed: 'var(--success-pressed)',
        },
        warning: {
          accent: 'var(--warning-accent)',
          container: 'var(--warning-container)',
          DEFAULT: 'var(--warning)',
          status: 'var(--warning-status)',
          hover: 'var(--warning-hover)',
          pressed: 'var(--warning-pressed)',
        },
        danger: {
          accent: 'var(--danger-accent)',
          container: 'var(--danger-container)',
          DEFAULT: 'var(--danger)',
          strong: 'var(--danger-strong)',
          hover: 'var(--danger-hover)',
          pressed: 'var(--danger-pressed)',
        },
        info: {
          accent: 'var(--info-accent)',
          container: 'var(--info-container)',
          DEFAULT: 'var(--info)',
          strong: 'var(--info-strong)',
          hover: 'var(--info-hover)',
          pressed: 'var(--info-pressed)',
        },
        shadow: {
          weak: 'var(--shadow-weak)',
          DEFAULT: 'var(--shadow)',
          strong: 'var(--shadow-strong)',
        },
        tremor: {
          brand: {
            faint: 'var(--primary-accent)',
            muted: 'var(--primary-container)',
            subtle: 'var(--primary-stroke)',
            DEFAULT: 'var(--primary)',
            emphasis: 'var(--primary-pressed)',
            inverted: 'var(--on-primary)',
          },
          background: {
            muted: 'var(--disabled)',
            subtle: 'var(--container)',
            DEFAULT: 'var(--background)',
            emphasis: 'var(--container-high)',
          },
          border: {
            DEFAULT: 'var(--stroke)',
          },
          ring: {
            DEFAULT: 'var(--primary)',
          },
          content: {
            subtle: 'var(--on-disabled)',
            DEFAULT: 'var(--subtle)',
            emphasis: 'var(--secondary)',
            strong: 'var(--foreground)',
            inverted: 'var(--background)',
          },
        },
      },
      borderRadius: {
        'radius-xs': 'var(--radius-xs)',
        'radius-sm': 'var(--radius-sm)',
        radius: 'var(--radius)',
        'radius-md': 'var(--radius-md)',
        'radius-lg': 'var(--radius-lg)',
      },
      keyframes: {
        'accordion-down': {
          from: {
            height: '0',
          },
          to: {
            height: 'var(--radix-accordion-content-height)',
          },
        },
        'accordion-up': {
          from: {
            height: 'var(--radix-accordion-content-height)',
          },
          to: {
            height: '0',
          },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
      },
      fontSize: {
        'tremor-label': [
          '0.75rem',
          {
            lineHeight: '1rem',
          },
        ],
        'tremor-default': [
          '0.875rem',
          {
            lineHeight: '1.25rem',
          },
        ],
        'tremor-title': [
          '1.125rem',
          {
            lineHeight: '1.75rem',
          },
        ],
        'tremor-metric': [
          '1.875rem',
          {
            lineHeight: '2.25rem',
          },
        ],
      },
      boxShadow: {
        low: '0px 1px 8px 0px var(--shadow-weak)',
        medium: '0px 4px 20px 0px var(--shadow-weak)',
        high: '0px 1px 8px 0px var(--shadow), 0px 16px 40px 0px var(--shadow-strong)',
      },
    },
  },
  plugins: [require('tailwindcss-animate')],
  safelist: [
    {
      pattern:
        /^(?:bg|bg-on|text|text-on|border|border-on|fill|ring)-(?:primary|neutral|danger)(?:|-sub|-container|-accent|-pressed|-hover)$/,
      variants: ['hover', 'ui-selected', 'active'],
    },
    {
      pattern:
        /^(bg-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
      variants: ['hover', 'ui-selected'],
    },
    {
      pattern:
        /^(text-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
      variants: ['hover', 'ui-selected'],
    },
    {
      pattern:
        /^(border-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
      variants: ['hover', 'ui-selected'],
    },
    {
      pattern:
        /^(ring-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
    },
    {
      pattern:
        /^(stroke-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
    },
    {
      pattern:
        /^(fill-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
    },
    {
      pattern:
        /^(?:bg|bg-on|text|text-on|border|border-on|fill|ring)-(?:primary|neutral|danger)(?:|-sub|-container|-accent|-pressed|-hover)$/,
      variants: ['hover', 'ui-selected', 'active'],
    },
  ],
}

`;

export const viteRepository: FileSystemTree & {
  src: DirectoryNode;
} = {
  'vite.config.ts': {
    file: {
      contents: `
import { defineConfig } from 'vite';
import react from '@vitejs/plugin-react';
import tsConfigPaths from 'vite-tsconfig-paths';

// https://vitejs.dev/config/
export default defineConfig({
  plugins: [react(), tsConfigPaths()]
})

`,
    },
  },
  'tsconfig.json': {
    file: {
      contents: `
{
  "compilerOptions": {
    "target": "ESNext",
    "useDefineForClassFields": true,
    "lib": ["DOM", "DOM.Iterable", "ESNext"],
    "allowJs": false,
    "skipLibCheck": false,
    "esModuleInterop": false,
    "allowSyntheticDefaultImports": true,
    "strict": true,
    "forceConsistentCasingInFileNames": true,
    "module": "ESNext",
    "moduleResolution": "Node",
    "resolveJsonModule": true,
    "isolatedModules": true,
    "noEmit": true,
    "jsx": "react-jsx",
    "paths": {
      "@/*": ["./src/*"]
    },
  },
  "include": ["./src", "./src/**/*.ts", "./src/**/*.tsx"]
}

`,
    },
  },
  'tailwind.config.js': {
    file: {
      contents: tailwindConfigBase,
    },
  },
  'postcss.config.js': {
    file: {
      contents: `
module.exports = {
  plugins: {
    tailwindcss: {},
    autoprefixer: {}
  }
}

`,
    },
  },
  'package.json': {
    file: {
      contents: packageJson,
    },
  },
  'index.html': {
    file: {
      contents: `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <link rel="icon" type="image/svg+xml" href="/src/favicon.svg" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Temp App</title>
  </head>
  <body style="height: 100vh; display: flex; margin: 0; background: #f5f5f5; padding: 12px; width: 100%; min-width: fit-content;" >
    <div
      id="root"
      class="h-auto w-full block ml-auto mr-auto mt-auto mb-auto max-w-fit"
    ></div>
    <script type="module" src="/src/main.tsx"></script>
  </body>
</html>

`,
    },
  },
  src: {
    directory: {
      'globals.css': {
        file: {
          contents: globalsCssBase,
        },
      },
      'App.tsx': {
        file: {
          contents: MainAppFile,
        },
      },
      'favicon.svg': {
        file: {
          contents: `
<svg width="410" height="404" viewBox="0 0 410 404" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M399.641 59.5246L215.643 388.545C211.844 395.338 202.084 395.378 198.228 388.618L10.5817 59.5563C6.38087 52.1896 12.6802 43.2665 21.0281 44.7586L205.223 77.6824C206.398 77.8924 207.601 77.8904 208.776 77.6763L389.119 44.8058C397.439 43.2894 403.768 52.1434 399.641 59.5246Z" fill="url(#paint0_linear)"/>
<path d="M292.965 1.5744L156.801 28.2552C154.563 28.6937 152.906 30.5903 152.771 32.8664L144.395 174.33C144.198 177.662 147.258 180.248 150.51 179.498L188.42 170.749C191.967 169.931 195.172 173.055 194.443 176.622L183.18 231.775C182.422 235.487 185.907 238.661 189.532 237.56L212.947 230.446C216.577 229.344 220.065 232.527 219.297 236.242L201.398 322.875C200.278 328.294 207.486 331.249 210.492 326.603L212.5 323.5L323.454 102.072C325.312 98.3645 322.108 94.137 318.036 94.9228L279.014 102.454C275.347 103.161 272.227 99.746 273.262 96.1583L298.731 7.86689C299.767 4.27314 296.636 0.855181 292.965 1.5744Z" fill="url(#paint1_linear)"/>
<defs>
<linearGradient id="paint0_linear" x1="6.00017" y1="32.9999" x2="235" y2="344" gradientUnits="userSpaceOnUse">
<stop stop-color="#41D1FF"/>
<stop offset="1" stop-color="#BD34FE"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="194.651" y1="8.81818" x2="236.076" y2="292.989" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFEA83"/>
<stop offset="0.0833333" stop-color="#FFDD35"/>
<stop offset="1" stop-color="#FFA800"/>
</linearGradient>
</defs>
</svg>

`,
        },
      },
      'index.css': {
        file: {
          contents: `
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

`,
        },
      },
      'main.tsx': {
        file: {
          contents: `
import React from 'react'
import './globals.css'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

`,
        },
      },
      'vite-env.d.ts': {
        file: {
          contents: `
/// <reference types="vite/client" />

`,
        },
      },
    },
  },
};
