import clsx from 'clsx';
import { useMemo } from 'react';

type EmbedFigmaProps = {
  url: string;
  className?: string;
  width?: number;
  height?: number;
};

function EmbedFigma({ url, className }: EmbedFigmaProps) {
  // use memo to get the embed URL from url
  const embedUrl = useMemo(() => {
    // First, ensure this is a figma link
    const figmaUrl = new URL(url);
    const pathname = figmaUrl.pathname.split('/');
    const fileKey = pathname[2];
    const nodeId = figmaUrl.searchParams.get('node-id');
    const fileName = pathname[3];
    if (fileKey && nodeId && fileName) {
      // Construct the embed URL
      return `https://embed.figma.com/design/${fileKey}/${fileName}?node-id=${nodeId}&embed-host=share`;
    }
    return '';
  }, [url]);

  return (
    <iframe width={'100%'} src={embedUrl} className={clsx('rounded-lg border-gray-100 border-2', className)}></iframe>
  );
}

export default EmbedFigma;
