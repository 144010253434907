import { type LintMessage } from '@ion/api';
import clsx from 'clsx';
import React from 'react';

import { Icons } from './icons';

interface Props {
  status: LintMessage['status'];
}
function StatusIcon({ status }: Props) {
  const icons: Record<LintMessage['status'], React.ReactNode> = {
    info: <Icons.info className="h-4 w-4" />,
    warning: <Icons.warning className="h-4 w-4" />,
    error: <Icons.close className="h-4 w-4" />,
  };
  return (
    <div
      className={clsx('aspect-square h-6 w-6 rounded-radius flex items-center justify-center shrink-0', {
        'bg-info-container text-on-info-container': status === 'info',
        'bg-warning-container text-on-warning-container': status === 'warning',
        'bg-danger-container text-on-danger-container': status === 'error',
      })}
    >
      {icons[status]}
    </div>
  );
}

function LinterMessage({ message, status }: LintMessage) {
  return (
    <div className="bg-background" key={message}>
      <div className="flex items-center gap-2">
        <StatusIcon status={status} />
        <div className="flex flex-col">
          <div className="text-xs text-soft text-secondary">{message}</div>
        </div>
      </div>
    </div>
  );
}

export default LinterMessage;
