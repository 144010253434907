// Generated with Ion on 10/7/2024, 11:29:44 PM
// Figma Link: https://www.figma.com/design/gEP8BXRhEUxCBGcLorxfgS?node-id=475:7445
'use client';

import { CONNECT_FIGMA_FILE_URL } from '@api/constants/figma';
import { PosthogEvents, trackEvent } from '@api/posthog';
import { type ComponentRetrieved } from '@api/types';
import type { UnmatchedFigmaComponent } from '@api/types/figma';
import type { File } from '@api/types/updates';
import { api } from '@ion/api';
import {
  Badge,
  Button,
  Card,
  Divider,
  Input,
  Label,
  Modal,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
  RadioGroupItem,
  Switch,
  Textarea,
} from '@ion/design-system';
import { useSession } from '@ion/next-auth/react';
import type { FileSystemTree } from '@webcontainer/api';
import clsx from 'clsx';
import { env } from 'env.mjs';
import Link from 'next/link';
import React, { useMemo, useState } from 'react';
import { z } from 'zod';

import EmbedFigma from '@/components/embed-figma';
import { Icons } from '@/components/icons';
import Spinner from '@/components/ui/spinner';
import {
  type TsxFile,
  getGlobalsCssFromFileTree,
  getTailwindConfigFromFileTree,
  processFileTreeToTsxFiles,
} from '@/lib/utils';
import { MainAppFile, packageJson } from '@/lib/web-container-config';

import AnimatedLoader from './animated-loader';
import { MatchedComponentGenerationPreview, UnmatchedComponentGenerationPreview } from './component-generation-preview';
import LinterMessage from './linter-message';
import { ToggleTheme } from './toggle-theme';

interface ContentProps {
  type: 'default' | 'onboarding' | 'modal';
  state: 'idle' | 'selected' | 'prepared' | 'error' | 'onboarding' | 'generating';
  setState: (state: 'idle' | 'selected' | 'prepared' | 'error' | 'onboarding' | 'generating') => void;
  tsxFiles?: TsxFile[];
  globalsCss?: string;
  tailwindConfig?: string;
  onGenerationComplete?: (files: File[], mainFile: string) => void;
}

interface CreateGenerationCardProps {
  type: 'default' | 'onboarding' | 'modal';
}

interface CreateGenerationModalProps {
  trigger?: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  fileTree?: FileSystemTree;
  onGenerationComplete?: (files: File[], mainFile: string) => void;
}

export function CreateGenerationContent({
  type,
  state,
  setState,
  tsxFiles,
  globalsCss,
  tailwindConfig,
  onGenerationComplete,
}: ContentProps) {
  const [figmaLink, setFigmaLink] = useState<string>('');
  const { data } = useSession();
  const [fileKey, setFileKey] = useState<string>('');
  const [nodeId, setNodeId] = useState<string>('');
  const [generationType, setGenerationType] = useState<'page' | 'component'>('page');
  const [routePath, setRoutePath] = useState<string>('');
  const [preparedData, setPreparedData] = useState<{
    destinationPath: string;
    matchedComponents: ComponentRetrieved[];
    unmatchedComponents: UnmatchedFigmaComponent[];
  } | null>(null);
  const [generateWithCss, setGenerateWithCss] = useState<'custom' | 'default'>('default');
  const [userGlobalCss, setUserGlobalCss] = useState<string | undefined>(undefined);
  const [userTailwindConfig, setUserTailwindConfig] = useState<string | undefined>(undefined);
  const [inferFunctionality, setInferFunctionality] = useState<boolean>(false);

  const { data: linterData, isLoading: isLinterLoading } = api.generations.lintWeb.useQuery(
    { fileKey, nodeId },
    {
      enabled: state === 'selected',
      queryKey: [
        'generations.lintWeb',
        {
          fileKey,
          nodeId,
        },
      ],
    }
  );

  const { mutate: prepareFromWeb, isLoading: isPreparedLoading } = api.generations.prepareGenerationWeb.useMutation({
    onSuccess(data, variables, context) {
      setPreparedData(data);
    },
  });

  const { mutate: generateNewComponentFromWeb, isLoading } = api.generations.generateNewComponentFromWeb.useMutation({
    onSuccess: (id) => {
      if (window.history) {
        history.pushState({}, window.location.href);
      }
      if (type === 'onboarding') {
        window.location.replace(`/generation/${id}?isOnboarding=true`);
      } else {
        window.location.replace(`/generation/${id}`);
      }
    },
  });

  const { mutate: generateFromWeb, isLoading: isGenerating } = api.generations.generateFromWeb.useMutation({
    onSuccess: (data) => {
      if (onGenerationComplete) {
        const mainGenerationFile = preparedData?.destinationPath ?? '';
        onGenerationComplete(data.files, mainGenerationFile.split('/').pop() ?? '');
      }
    },
  });

  const ONBOARDING_NODE_ID = '2:3783';
  const ONBOARDING_FILE_KEY = 'kQxbDOWBPaC4KqJOhw4JvW';

  const selectGeneration = (
    { isOnboarding }: { isOnboarding: boolean } | undefined = {
      isOnboarding: false,
    }
  ) => {
    if (isOnboarding) {
      setFileKey(ONBOARDING_FILE_KEY);
      setNodeId(ONBOARDING_NODE_ID);
      setFigmaLink(`https://www.figma.com/design/${ONBOARDING_FILE_KEY}/ion-onboarding?node-id=${ONBOARDING_NODE_ID}`);

      // In the background, start preparing the generation
      prepareFromWeb({ fileKey: ONBOARDING_FILE_KEY, nodeId: ONBOARDING_NODE_ID });
    } else {
      const figmaUrl = new URL(figmaLink);
      const pathname = figmaUrl.pathname.split('/');
      const extractedFileKey = pathname[2];
      const extractedNodeId = figmaUrl.searchParams.get('node-id')?.replace('-', ':');
      setFileKey(extractedFileKey ?? '');
      setNodeId(extractedNodeId ?? '');

      // In the background, start preparing the generation
      prepareFromWeb({ fileKey: extractedFileKey ?? '', nodeId: extractedNodeId ?? '', components: tsxFiles });
    }
    setState('selected');
  };

  const lintMessageLengths = useMemo(() => {
    // Count the number of each type of message, error warning and info.
    if (!linterData) return { error: 0, warning: 0, info: 0 };

    const { messages } = linterData;
    const error = messages.filter((message) => message.status === 'error').length;
    const warning = messages.filter((message) => message.status === 'warning').length;
    const info = messages.filter((message) => message.status === 'info').length;
    return { error, warning, info };
  }, [linterData]);

  const onGenerateClick = () => {
    if (type === 'modal') {
      generateFromWeb({
        fileKey,
        nodeId,
        destinationPath: preparedData?.destinationPath ?? 'src/components/ion/Generation.tsx',
        packageJson,
        fontLocations: [
          {
            type: 'vite',
            content: MainAppFile,
            path: 'src/App.tsx',
          },
        ],
        components: preparedData?.matchedComponents ?? [],
        globalsCss,
        tailwindConfig,
      });
      setState('generating');
    } else {
      const generationGlobalsCss = generateWithCss === 'custom' ? userGlobalCss : globalsCss;
      const generationTailwindConfig = generateWithCss === 'custom' ? userTailwindConfig : tailwindConfig;

      generateNewComponentFromWeb({
        fileKey,
        nodeId,
        packageJson,
        generatedFrom: type === 'onboarding' ? 'onboarding' : 'default',
        fontLocations: [
          {
            type: 'vite',
            content: MainAppFile,
            path: 'src/App.tsx',
          },
        ],
        globalsCss: !generationGlobalsCss ? undefined : generationGlobalsCss,
        tailwindConfig: !generationTailwindConfig ? undefined : generationTailwindConfig,
        useExperimental: inferFunctionality,
      });
      setState('generating');
    }
  };

  const cardContent = () => {
    if (!data?.user.figmaAccessToken) {
      return (
        <Button
          className="w-full"
          iconLeading={<Icons.figma className="w-4 h-4" />}
          onClick={() =>
            window.open(
              CONNECT_FIGMA_FILE_URL(
                `${env.NEXT_PUBLIC_BASE_URL}/${type === 'onboarding' ? 'onboarding' : 'generation'}`
              ),
              '_blank'
            )
          }
        >
          Connect Figma Account
        </Button>
      );
    }

    if (state === 'onboarding' && type === 'onboarding') {
      return (
        <div className="flex flex-col gap-2">
          <Button
            className="w-full"
            onClick={() => {
              trackEvent(PosthogEvents.CLICKED_USE_ONBOARDING_SAMPLE, data.user);
              selectGeneration({
                isOnboarding: true,
              });
            }}
            iconLeading={isLoading ? <Spinner size="sm" /> : undefined}
            disabled={isLoading}
          >
            Try sample generation
          </Button>
          <Button
            className="w-full"
            variant="soft"
            onClick={() => {
              setState('idle');
              trackEvent(PosthogEvents.CLICKED_USE_OWN_FIGMA_LINK_FROM_ONBOARDING, data.user);
            }}
          >
            I have my own Figma Link
          </Button>
        </div>
      );
    }
    switch (state) {
      case 'idle':
        return (
          <>
            {type === 'modal' && (
              <div className="w-full flex flex-col gap-4 transition-all duration-200">
                <div className="w-full flex flex-col gap-2">
                  <Label>Generation Type</Label>
                  <RadioGroup
                    value={generationType}
                    onValueChange={(val) => setGenerationType(val as 'page' | 'component')}
                    orientation="horizontal"
                  >
                    <div className="flex gap-4">
                      <RadioGroupItem value="page" label="Page" />
                      <RadioGroupItem value="component" label="Component" />
                    </div>
                  </RadioGroup>
                </div>
                <Input
                  label="Page Route"
                  required
                  placeholder="/myPage"
                  value={routePath}
                  onChange={(e) => setRoutePath(e.target.value)}
                  className={clsx({
                    'transition-all duration-200': true,
                    hidden: generationType === 'component',
                  })}
                  hint="The URL path for your page, starting with the forward slash"
                />
              </div>
            )}
            <Input
              label="Figma Link"
              required
              placeholder="https://www.figma.com/design/...."
              value={figmaLink}
              onChange={(e) => setFigmaLink(e.target.value)}
              hint="Select node in Figma, press CMD+L, paste here"
            />

            <div className="flex justify-end pt-4 gap-2">
              {type === 'onboarding' && (
                <Button onClick={() => setState('onboarding')} variant="soft" color="neutral">
                  Go back
                </Button>
              )}

              <Button
                onClick={() => selectGeneration()}
                iconLeading={<Icons.lightning className="h-4 w-4" />}
                disabled={z.string().url().safeParse(figmaLink).success === false}
              >
                Select Generation
              </Button>
            </div>
          </>
        );

      case 'selected':
        return (
          <>
            <div className="flex items-center flex-col gap-4 h-full overflow-y-auto flex-1">
              {type === 'modal' ? (
                // <>
                //   {linterData && linterData.previewImage && (
                //     <img src={linterData.previewImage} className="h-full max-h-96 shrink-0 basis-96" />
                //   )}
                // </>
                <></>
              ) : (
                <EmbedFigma url={figmaLink} className="h-full max-h-96 shrink-0 basis-96" />
              )}
              <div className="w-full flex flex-col gap-4 h-full overflow-y-auto max-w-[600px]">
                <div className="flex w-full justify-between items-center">
                  <Popover>
                    <PopoverTrigger asChild>
                      <div className="flex gap-2 items-center cursor-pointer">
                        <span className="font-semibold">dsLint Messages</span>
                        <Icons.question className="h-4 w-4" />
                      </div>
                    </PopoverTrigger>
                    <PopoverContent sideOffset={8} align="center" className="border border-stroke w-[400px]">
                      <div className="p-4 flex flex-col gap-4">
                        <span className="font-semibold">What is dsLint?</span>
                        <div className="flex flex-col gap-2 text-sm text-secondary">
                          <span>
                            Linting is a process of running a program that will analyze code for potential errors. We
                            made one for your Figma designs!
                          </span>
                          <span>It will help you catch potential issues in your designs before you generate code.</span>
                          <span>
                            We call it dsLint, like how esLint is for JavaScript and tsLint is for Typescript :)
                          </span>
                        </div>
                        <div className="w-full flex justify-end">
                          <Button variant="ghost" iconTrailing={<Icons.chevronRight className="h-4 w-4" />}>
                            psst - it also has a Figma Plugin
                          </Button>
                        </div>
                      </div>
                    </PopoverContent>
                  </Popover>
                  <div className="flex gap-2 h-fit">
                    <Badge type="number" color="red" size="lg" variant="soft" className="h-fit px-2 py-1">
                      {lintMessageLengths.error}
                    </Badge>
                    <Badge type="number" color="yellow" size="lg" variant="soft" className="h-fit px-2 py-1">
                      {lintMessageLengths.warning}
                    </Badge>
                    <Badge type="number" color="blue" size="lg" variant="soft" className="h-fit px-2 py-1">
                      {lintMessageLengths.info}
                    </Badge>
                  </div>
                </div>
                {isLinterLoading && (
                  <div className="w-full flex justify-center h-full overflow-hidden">
                    <Spinner size="sm" />
                  </div>
                )}
                {linterData && (
                  <>
                    {linterData.messages.length > 0 ? (
                      linterData.messages.map((message) => <LinterMessage {...message} />)
                    ) : (
                      <div className="w-full flex items-center justify-center flex-col gap-2 overflow-y-auto">
                        <div className="bg-success-container h-8 w-8 rounded-full flex items-center justify-center">
                          <Icons.check className="text-on-success-container h-4 w-4" />
                        </div>
                        <span className="text-soft">You're all set!</span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="w-full flex justify-end gap-4">
              <Button onClick={() => setState('idle')} variant="soft" color="neutral">
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => setState('prepared')}
                variant="filled"
                iconLeading={isLoading ? <Spinner size="sm" /> : <Icons.lightning className="h-4 w-4" />}
              >
                {isLoading ? 'Preparing...' : 'Prepare Generation'}
              </Button>
            </div>
          </>
        );
      case 'prepared':
        return isPreparedLoading ? (
          <AnimatedLoader
            isElevated={false}
            messages={[
              'Connecting to Figma...',
              'Understanding your codebase...',
              'Splitting Figma Components...',
              'Matching Components...',
              'Creating Plan...',
            ]}
          />
        ) : (
          <>
            <Popover>
              <PopoverTrigger asChild>
                <div className="flex gap-2 items-center cursor-pointer w-fit">
                  <span className="text-secondary font-semibold">Generation Plan</span>
                  <Icons.question className="h-4 w-4" />
                </div>
              </PopoverTrigger>
              <PopoverContent sideOffset={8} className="border border-stroke w-[400px]">
                <div className="flex flex-col gap-3 text-sm text-secondary p-4">
                  <span>
                    We've analyzed your design and prepared a generation plan for you. This includes components that
                    have been matched in the codebase, and how the generation will be split up into sub-components.
                  </span>
                  <span>
                    Each sub-generation can also be generated as a primitive component, which is a basic component with
                    functionality like a button or input.
                  </span>
                  <span>
                    You can review the plan and generate code. If you're not happy with the plan, you can modify it to
                    your liking.
                  </span>
                </div>
              </PopoverContent>
            </Popover>
            <div className="flex flex-col gap-4 h-full overflow-y-auto">
              {type === 'modal' ? (
                <span className="text-sm">
                  Creating a new component to file <span className="font-mono">{preparedData?.destinationPath}</span>
                </span>
              ) : (
                <>
                  <Switch
                    checked={inferFunctionality}
                    onCheckedChange={(checked) => setInferFunctionality(checked)}
                    label="Infer structure & functionality"
                    description="Makes generation higher quality. Note that this could modify props and may not always be accurate."
                  />
                  <div className="w-full flex flex-col gap-2">
                    <Label>Tailwind and Css settings:</Label>
                    <RadioGroup
                      value={generateWithCss}
                      onValueChange={(val) => setGenerateWithCss(val as 'custom' | 'default')}
                      orientation="horizontal"
                    >
                      <div className="flex gap-4">
                        <RadioGroupItem value="default" label="Use default" />
                        <RadioGroupItem value="custom" label="Use my own css and tailwind config" />
                      </div>
                    </RadioGroup>
                  </div>
                </>
              )}
              {generateWithCss === 'custom' && (
                <div className="w-full flex gap-4">
                  <Textarea
                    label="Global CSS"
                    placeholder="body { font-family: 'Arial'; }"
                    value={userGlobalCss}
                    onChange={(e) => setUserGlobalCss(e.target.value)}
                    className="flex-1"
                  />
                  <Textarea
                    label="Tailwind Config"
                    placeholder="module.exports = { ... }"
                    value={userTailwindConfig}
                    onChange={(e) => setUserTailwindConfig(e.target.value)}
                    className="flex-1"
                  />
                </div>
              )}
              {preparedData && preparedData.matchedComponents.length != 0 && (
                <div className="bg-container rounded-xl p-4 flex flex-col gap-4">
                  <span className="text-sm font-medium">
                    Components that have been matched to existing React Components:
                  </span>
                  <div className="grid grid-cols-3 gap-x-6 gap-y-6">
                    {preparedData.matchedComponents.map((match) => (
                      <MatchedComponentGenerationPreview key={match.id} matchedComponent={match} />
                    ))}
                  </div>
                </div>
              )}
              {preparedData && preparedData.unmatchedComponents.length != 0 && (
                <div className="bg-container rounded-xl p-4 flex flex-col gap-4">
                  <span className="text-sm text-secondary px-2">New sub-components that will be generated:</span>
                  <div className="grid grid-cols-3 gap-x-6 gap-y-6">
                    {preparedData.unmatchedComponents.map((comp) => (
                      <UnmatchedComponentGenerationPreview key={comp.componentId} unmatchedComponent={comp} />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full flex justify-end gap-4">
              <Button onClick={() => setState('selected')} variant="soft" color="neutral">
                Go Back
              </Button>
              <Button
                disabled={isLoading}
                onClick={onGenerateClick}
                variant="filled"
                iconLeading={isLoading ? <Spinner size="sm" /> : <Icons.lightning className="h-4 w-4" />}
              >
                {isLoading ? 'Generating...' : 'Generate'}
              </Button>
            </div>
          </>
        );
      case 'generating':
        return (
          <>
            <AnimatedLoader isElevated={false} />
          </>
        );
      case 'error':
        return (
          <>
            <span className="font-semibold text-lg">Error Preparing Generation</span>
            <Divider />
            <span>Something went wrong...</span>
          </>
        );
    }
  };

  return <div className="flex flex-col gap-4 h-full">{cardContent()}</div>;
}

export function CreateGenerationCard({ type }: CreateGenerationCardProps) {
  const { data } = useSession();
  const [state, setState] = useState<'idle' | 'selected' | 'prepared' | 'error' | 'onboarding' | 'generating'>(
    type === 'onboarding' ? 'onboarding' : 'idle'
  );

  return (
    <Card
      title={'New Generation'}
      subtitle={
        !data?.user.figmaAccessToken
          ? 'Connect your Figma account to get started'
          : type === 'onboarding'
          ? 'Get started with ion design'
          : ''
      }
      type="elevated"
      className={clsx({
        'transition-all duration-500 max-h-full overflow-y-auto max-w-screen-lg relative': true,
        'sm:w-[400px] w-fit duration-0': state === 'idle' || state === 'error' || state === 'onboarding',
        'w-full': state === 'selected' || state === 'prepared',
        'bg-danger-container': state === 'error',
      })}
    >
      <div className="absolute right-5 top-5 flex flex-row gap-2">
        <Link href="/settings">
          <Button variant="ghost" iconLeading={<Icons.gear className="h-4 w-4" />} />
        </Link>
        <ToggleTheme />
      </div>
      <CreateGenerationContent type={type} state={state} setState={setState} />
    </Card>
  );
}

export function CreateGenerationModal({
  trigger,
  open,
  onOpenChange,
  fileTree,
  onGenerationComplete,
}: CreateGenerationModalProps) {
  const { data } = useSession();
  const [state, setState] = useState<'idle' | 'selected' | 'prepared' | 'error' | 'onboarding' | 'generating'>('idle');

  const tsxFiles = useMemo(() => {
    // get path, content, and componentName from the file system tree
    return fileTree ? processFileTreeToTsxFiles(fileTree) : [];
  }, [fileTree]);

  const globalsCss = useMemo(() => {
    // get the content of the globals.css file from the file system tree
    return fileTree ? getGlobalsCssFromFileTree(fileTree) : undefined;
  }, [fileTree]);

  const tailwindConfig = useMemo(() => {
    return fileTree ? getTailwindConfigFromFileTree(fileTree) : undefined;
  }, [fileTree]);

  const generationComplete = (files: File[], mainFile: string) => {
    if (onGenerationComplete) onGenerationComplete(files, mainFile);
    if (onOpenChange) onOpenChange(false);
  };

  return (
    <Modal
      showClose
      open={open}
      onOpenChange={onOpenChange}
      title={'New Generation'}
      trigger={trigger}
      subtitle={!data?.user.figmaAccessToken ? 'Connect your Figma account to get started' : ''}
      footerBordered={false}
      headerBordered={false}
      className={clsx({
        'transition-all duration-500 max-h-full overflow-y-auto max-w-screen-lg': true,
        'sm:w-[400px] w-fit duration-0': state === 'idle' || state === 'error' || state === 'onboarding',
        'w-full': state === 'selected',
        'w-full h-4/5': state === 'prepared',
        'bg-danger-container': state === 'error',
      })}
    >
      <CreateGenerationContent
        type="modal"
        state={state}
        setState={setState}
        tsxFiles={tsxFiles}
        globalsCss={globalsCss}
        tailwindConfig={tailwindConfig}
        onGenerationComplete={generationComplete}
      />
    </Modal>
  );
}
