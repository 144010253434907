import { env } from '@api/env';
import type * as Figma from '@knapsack-labs/figma-api';

export type FigmaPaintWithHex = {
  paint: Figma.Paint;
  hex?: string;
};

export type FigmaComponentsType = {
  [nodeId: string]: Figma.Component;
};

export type FigmaImagesType = {
  [imageRef: string]: string | null;
};

export type FigmaPropsType = {
  [key: string]: string;
};

export const redirectLink = `${env.NEXT_PUBLIC_BASE_URL}/manage`;
const state = JSON.stringify({ figmaLink: 'required unused param' });
export const ADD_FIGMA_FILE_API_URL = `https://www.figma.com/oauth?client_id=${env.NEXT_PUBLIC_FIGMA_CLIENT_ID}&redirect_uri=${redirectLink}&scope=file_read&state=${state}&response_type=code`;
export const CONNECT_FIGMA_FILE_URL = (redirect: string) =>
  `https://www.figma.com/oauth?client_id=${env.NEXT_PUBLIC_FIGMA_CLIENT_ID}&redirect_uri=${redirect}&scope=file_read&state=${state}&response_type=code`;

export type FigmaGetNodeResponseType = {
  document: Figma.Node<keyof Figma.NodeTypes>;
  components: {
    [nodeId: string]: Figma.Component;
  };
  componentSets: ComponentSets; // For whatever reason, this isn't in the Figma API package
  schemaVersion: number;
  styles: {
    [styleName: string]: Figma.Style;
  };
};

export type ComponentSets = {
  [componentSetId: string]: ComponentSet;
};

export type ComponentSet = {
  key: string;
  name: string;
  description: string;
  remote: boolean;
};
