import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

const defaultMessages = [
  'Getting context from codebase...',
  'Building out display logic...',
  'Writing the CSS...',
  'Optimizing the code...',
  'Adding final touches...',
];

const AnimatedLoader = ({ messages = defaultMessages, isElevated = true }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prev) => (prev + 1) % messages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [messages]);

  return (
    <div
      className={clsx({
        'w-full h-full relative rounded-lg p-8 overflow-hidden flex flex-col': true,
        'shadow-lg bg-background': isElevated,
      })}
    >
      {/* Message Display */}
      <div className="text-secondary h-6 font-mono text-base text-center relative z-10">
        {messages[currentMessageIndex]}
      </div>

      {/* Animation Container */}
      <div className="flex-1 relative flex items-center justify-center">
        <style>
          {`
            @keyframes topLeftBox {
              0% { transform: translate(0, 0) scale(1); }
              25% { transform: translate(60%, 0) scale(0.9); }
              50% { transform: translate(60%, 60%) scale(1); }
              75% { transform: translate(0, 60%) scale(0.9); }
              100% { transform: translate(0, 0) scale(1); }
            }
            
            @keyframes topRightBox {
              0% { transform: translate(0, 0) scale(1); }
              25% { transform: translate(0, 60%) scale(0.9); }
              50% { transform: translate(-60%, 60%) scale(1); }
              75% { transform: translate(-60%, 0) scale(0.9); }
              100% { transform: translate(0, 0) scale(1); }
            }
            
            @keyframes bottomRightBox {
              0% { transform: translate(0, 0) scale(1); }
              25% { transform: translate(-60%, 0) scale(0.9); }
              50% { transform: translate(-60%, -60%) scale(1); }
              75% { transform: translate(0, -60%) scale(0.9); }
              100% { transform: translate(0, 0) scale(1); }
            }
            
            @keyframes bottomLeftBox {
              0% { transform: translate(0, 0) scale(1); }
              25% { transform: translate(0, -60%) scale(0.9); }
              50% { transform: translate(60%, -60%) scale(1); }
              75% { transform: translate(60%, 0) scale(0.9); }
              100% { transform: translate(0, 0) scale(1); }
            }
            
            .box {
              position: absolute;
              width: 120px;
              height: 120px;
              border-radius: 12px;
              transition: all 0.3s ease;
            }
            
            .top-left {
              animation: topLeftBox 8s infinite ease-in-out;
            }
            
            .top-right {
              animation: topRightBox 8s infinite ease-in-out;
            }
            
            .bottom-right {
              animation: bottomRightBox 8s infinite ease-in-out;
            }
            
            .bottom-left {
              animation: bottomLeftBox 8s infinite ease-in-out;
            }
          `}
        </style>

        <div className="relative w-[360px] h-[360px] flex items-center justify-center">
          {/* Top Left Box */}
          <div className="box top-left -translate-x-[100px] -translate-y-[100px] bg-info-accent border border-info-container">
            <div className="w-full h-3 bg-info-container mt-3 rounded mx-2" style={{ width: '85%' }} />
            <div className="w-3/4 h-3 bg-info-container mt-3 rounded mx-2" />
            <div className="w-1/2 h-3 bg-info-container mt-3 rounded mx-2" />
          </div>

          {/* Top Right Box */}
          <div className="box top-right translate-x-[100px] -translate-y-[100px] bg-success-accent border border-success-container">
            <div className="w-full h-3 bg-success-container mt-3 rounded mx-2" style={{ width: '85%' }} />
            <div className="w-2/3 h-3 bg-success-container mt-3 rounded mx-2" />
            <div className="w-3/4 h-3 bg-success-container mt-3 rounded mx-2" />
          </div>

          {/* Bottom Right Box */}
          <div className="box bottom-right translate-x-[100px] translate-y-[100px] bg-container border border-primary-container">
            <div className="w-1/2 h-3 bg-primary-container mt-3 rounded mx-2" />
            <div className="w-3/4 h-3 bg-primary-container mt-3 rounded mx-2" />
            <div className="w-3/4 h-3 bg-primary-container mt-3 rounded mx-2" />
          </div>

          {/* Bottom Left Box */}
          <div className="box bottom-left -translate-x-[100px] translate-y-[100px] bg-danger-accent border border-danger-container">
            <div className="w-3/4 h-3 bg-danger-container mt-3 rounded mx-2" />
            <div className="w-1/2 h-3 bg-danger-container mt-3 rounded mx-2" />
            <div className="w-3/4 h-3 bg-danger-container mt-3 rounded mx-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedLoader;
