import { type ComponentRetrieved } from '@api/types';
import type { UnmatchedFigmaComponent } from '@api/types/figma';
import Link from 'next/link';
import React from 'react';

import { Icons } from './icons';

type MatchedComponentGenerationPreviewProps = {
  matchedComponent: ComponentRetrieved;
};

type UnmatchedComponentGenerationPreviewProps = {
  unmatchedComponent: UnmatchedFigmaComponent;
};

export function MatchedComponentGenerationPreview({ matchedComponent }: MatchedComponentGenerationPreviewProps) {
  return (
    <div className="flex flex-col gap-2 p-4 rounded-lg bg-container-high w-full h-fit">
      <div className="flex justify-between items-center">
        <span className="text-sm">{matchedComponent.name}</span>
        <Link
          href={matchedComponent.figmaLink ?? '#'}
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer"
        >
          <Icons.figma className="h-4 w-4 cursor-pointer text-info" />
        </Link>
      </div>
      <span className="font-mono text-xs text-secondary max-w-full truncate">{matchedComponent.path}</span>
    </div>
  );
}

// Needs to display the Figma component name, generation path, a link to figma, and the primitive type that will be generated as a dropdown
// Should also have a checkbox to enable/disabled inferred functionality
// Should be a slightly shaded card
export function UnmatchedComponentGenerationPreview({ unmatchedComponent }: UnmatchedComponentGenerationPreviewProps) {
  const [inferFunctionality, setInferFunctionality] = React.useState(false);
  const [generateAsPrimitive, setGenerateAsPrimitive] = React.useState(false);
  const [primitiveType, setPrimitiveType] = React.useState<string>('none');

  React.useEffect(() => {
    if (unmatchedComponent.primitiveType && unmatchedComponent.primitiveType != undefined) {
      setGenerateAsPrimitive(true);
      setPrimitiveType(unmatchedComponent.primitiveType);
    }
  }, [unmatchedComponent]);

  return (
    <div className="flex flex-col gap-1 p-4 rounded-lg bg-container-high w-full h-fit">
      <div className="flex justify-between items-center">
        <span className="text-sm font-normal">{unmatchedComponent.sanitizedFigmaComponentName}</span>
        <Link href={unmatchedComponent.figmaLink} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
          <Icons.figma className="h-4 w-4 cursor-pointer text-info" />
        </Link>
      </div>
      <span className="font-mono text-xs text-secondary max-w-full truncate">{unmatchedComponent.generationPath}</span>
      {/* <div className="flex flex-col gap-4 my-2 w-full transition-all">
        <Checkbox
          label="Infer functionality"
          checked={inferFunctionality}
          onCheckedChange={(cs) => setInferFunctionality(cs == true)}
        />
        <Checkbox
          label="Generate as a primitive"
          checked={generateAsPrimitive}
          onCheckedChange={(cs) => {
            if (cs == false) setPrimitiveType('none');
            setGenerateAsPrimitive(cs == true);
          }}
        />
        {generateAsPrimitive && (
          <Select
            className="ease-in-out duration-200"
            label="Primitive Type"
            options={[
              { value: 'none', label: 'Do not generate as primitive' },
              { value: 'button', label: 'Button' },
              { value: 'badge', label: 'Badge' },
              { value: 'label', label: 'Label' },
              { value: 'hint', label: 'Hint' },
              { value: 'datepicker', label: 'Datepicker' },
              { value: 'divider', label: 'Divider' },
              { value: 'table', label: 'Table' },
              { value: 'tabs', label: 'Tabs' },
              { value: 'input', label: 'Input' },
              { value: 'select', label: 'Select' },
              { value: 'textarea', label: 'Textarea' },
              { value: 'radio', label: 'Radio' },
              { value: 'checkbox', label: 'Checkbox' },
              { value: 'switch', label: 'Switch' },
              { value: 'slider', label: 'Slider' },
              { value: 'progress', label: 'Progress' },
              { value: 'spinner', label: 'Spinner' },
              { value: 'tooltip', label: 'Tooltip' },
              { value: 'dialog', label: 'Dialog' },
              { value: 'menu', label: 'Menu' },
              { value: 'slider', label: 'Slider' },
              { value: 'toolbar', label: 'Toolbar' },
            ]}
            value={primitiveType}
            onValueChange={(v) => setPrimitiveType(v)}
          />
        )}
      </div> */}
    </div>
  );
}
